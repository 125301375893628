export default {
    'home':'棣栭〉',
    'about_us':'鍏充簬SigmaStar',
    'company_address':'鑱旂郴鎴戜滑',
    'contact_us':'鑱旂郴鎴戜滑',
    'market_and_solution':'鍦烘櫙鍙婂簲鐢?,
    'changjing':'鍦烘櫙',
    'cn':'涓枃',
    'en':'鑻辨枃',
    'news_center':'鏂伴椈涓績',
    'developer_Community':'寮€鍙戣€呯ぞ鍖?,
    'hr_resource':'浜哄姏璧勬簮',
    'training':'浜烘墠鍩硅偛',
    'join_us':'鍔犲叆鎴戜滑',
    'honor':'鑽ｈ獕璧勮川',
    'investor':'鎶曡祫鑰呭叧绯?,
    'investorService':'鎶曡祫鑰呮湇鍔?,
    'report':'鍏徃鍏憡',
    'stock_code':'鑲＄エ浠ｇ爜',
    'email':'閭',
    'kefu':'瀹㈡湇',
    'phone':'鐢佃瘽',
    'legalNotice':'娉曞緥澹版槑',
    'copyright':'鐗堟潈鍟嗘爣',
    'filing_number':'澶囨鍙凤細闂絀CP澶?9019072鍙?,
    'copyright_belongs':'鐗堟潈鎵€鏈夛細鏄熷绉戞妧鑲′唤鏈夐檺鍏徃',
    'market':'甯傚満',
    'solution':'琛屼笟',
    'pre':'涓婁竴绡?,
    'next':'涓嬩竴绡?,
    'rel_products':'鐩稿叧浜у搧',
    'feature':'鐗硅壊浜偣',
    'xxzx':'閫夊瀷鍜ㄨ',
     'go_to_choose_sim' :'杩涘叆閫夊瀷鍜ㄨ閫氶亾' ,
     'address':'鍦板潃',
     'express':'浼犵湡',

     

    


}